<template>
  <!-- Uvin Medialive Video Player Not Found -->
  <div class="w-100 vh-100 align-items-center d-flex justify-content-center">
    <div>
      <div class="text-center">
        <img
          class="img-fluid d-inline-block"
          width="194"
          height="53"
          :src="require('@/assets/images/logo-uvin-web.png')"
        />
        <img
          class="img-fluid d-inline-block"
          width="288"
          height="53"
          :src="require('@/assets/images/logo-medialive-web.png')"
        />
      </div>
      <div class="player-message">
        Uvin - MediaLive<span class="color-cyan">.Player</span>
        <br />
        <br />
        <span class="span-message color-red">Unauthorized access or video not found</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
